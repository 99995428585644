import React from 'react';

import Layout from '../components/layout';
import {TopWave} from '../components/waves';
import {Link} from 'gatsby';

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="relative -mt-12 lg:-mt-24">
        <TopWave />
      </div>
      <div className="bg-white text-gray-800 pb-10 px-4">
        <h1 className="py-6">Page not found</h1>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>
        <p>We couldn't find what you were looking for.</p>
        <p className="">
          <Link to="/">Go to home page</Link>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
